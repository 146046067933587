<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Quotations</h4>
                        </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addNewQuote()">Add New Quotation</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Company</label>
              <b-form-input v-model="search.client"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Ticket Number</label>
              <b-form-input v-model="search.jobCardNumber"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Quotation Status</label>
              <b-form-select v-model="search.state" :disabled="state === 'loadingData'">
                <b-form-select-option v-for="(item, index) in statusList" :key="index" :value="item.id">{{
                  item.description
                }}</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Company Account</label>
              <b-form-input v-model="search.clientAccountNumber"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Quotation Number</label>
              <b-form-input v-model="search.quoteNumber"></b-form-input>
            </b-col>
          </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
              <b-button variant="primary" squared @click="searchResults()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <b-table
                striped
                hover
                :items="tableData.dataSource"
                :fields="tableData.tableColumns"
                   :per-page="tableData.resultsPerPage"
                   :current-page="tableData.currentPage"
                :busy="tableData.isLoading"
                @row-clicked="openCard"
                sort-icon-left>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
                  </div>
                </template>

                <template #cell(ticketId)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{ padResult(row.item.ticketId) }}</span>
                  </b-row>
                </template>

                <template #cell(total)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">R {{ row.item.total.toFixed(2) }}</span>
                  </b-row>
                </template>

                <template #cell(quoteDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{ row.item.quoteDate | dateTimeFilter }}</span>
                  </b-row>
                </template>

                 <template #cell(createdBy)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{ row.item.createdBy }}</span>
                  </b-row>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openCard(row.item)" class="btn-icon" size="sm">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-pagination
              v-model="tableData.currentPage"
              :total-rows="rows"
              :per-page="tableData.resultsPerPage"
            ></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="addNew-modal" hide-footer no-close-on-backdrop @close="closeModal()">
      <template #modal-header="{}">
        <h5>Ticket Number</h5>
      </template>
      <b-row>
        <b-col cols="12">
          <label>Please enter a ticket number to connect to the quote</label>
          <b-form-input v-model="newQuoteTicket"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-right mt-3">
          <b-button variant="red" @click="hideModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="primary" @click="createNewQuote()" squared>Done</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'Logger',
  data: () => ({
    newQuoteTicket: null,
    statusList: [],
    search: {
      jobCardNumber: null,
      dateCreatedFrom: null,
      dateCreatedTo: null,
      state: null,
      quoteNumber: null,
      client: null,
    },
    tableData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'companyName',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Company Account',
          key: 'clientAccountNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Quotation Number',
          key: 'quoteNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Quotation Type',
          key: 'quoteType',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Ticket Number',
          key: 'ticketReferenceNo',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Date Quoted',
          key: 'quoteDate',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Created By',
          key: 'createdBy',
          sortable: true,
          tdClass: '',
        },
        
        {
          label: 'Total Excl. VAT',
          key: 'total',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
  }),
  created() {
    this.setBreadcrumb([
      {
        text: 'Quotes',
      },
      {
        text: 'Search',
        active: true,
      },
    ]);
    this.searchResults();
    this.state = 'loadingData';
    this.getStatusList()
      .then((res) => {
        this.statusList = res.data;
        this.state = 'show';
      })
      .catch(() => {
        this.state = 'show';
      });
  },
  methods: {
    ...mapActions(['getStatusList', 'getQuoteTypeList', 'searchQuotes', 'createQuote']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    addNewQuote() {
      this.$root.$emit('bv::show::modal', 'addNew-modal', '#btnShow');
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'addNew-modal', '#btnShow');
    },
    openCard(item) {
      this.$store.commit('setSelectedQuote', item);
      this.$router.push({ path: '/quotes/view' });
    },
    clearFilter() {
      this.search = {
        jobCardNumber: null,
        dateCreatedFrom: null,
        dateCreatedTo: null,
        state: null,
        quoteNumber: null,
        client: null,
      };
    },
    padResult(item) {
      if (item !== null && item !== undefined) {
        let newItem = item.toString().padStart(6, '0');
        return newItem;
      } else {
        return 'N/A';
      }
    },
    createNewQuote() {
      let request = {
        ticketId: this.newQuoteTicket,
      };
      this.$store.commit('setQuoteCreateRequest', request);
      this.createQuote()
        .then((res) => {
          this.$store.commit('setSelectedQuote', res.data);
          this.$router.push({ path: '/quotes/view' });
        })
        .catch(() => {
          this.hideModal();
        });
    },
    searchResults() {
      this.tableData.isLoading = true;

      let request = [];
      if (this.search.quoteNumber !== null) {
        request.push({ key: 'quoteNumber', value: this.search.quoteNumber });
      }
      if (this.search.client !== null) {
        request.push({ key: 'Name', value: this.search.client });
      }
      if (this.search.state !== null) {
        request.push({ key: 'fkstatusid', value: this.search.state });
      }
      if (this.search.jobCardNumber !== null) {
        request.push({ key: 'fkticketid', value: this.search.jobCardNumber });
      }

      this.$store.commit('setQuoteSearchRequest', request);
      this.searchQuotes()
        .then((res) => {
          this.tableData.dataSource = res.data;
          this.tableData.isLoading = false;
        })
        .catch(() => {
          this.tableData.isLoading = false;
        });
    },
  },
  computed: {
      rows() {
        return this.tableData.dataSource.length
      },
    },
};
</script>
